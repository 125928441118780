import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import Image from 'gatsby-plugin-sanity-image'
import PortableText from '../components/portable-text';
import FadeGallery from '../components/fade-gallery';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';

export default function HistoryItem({history, opened, onOpen}) {
  const [open, setOpen] = useState(opened);

  useEffect(() => {
    if (opened) {
      setOpen(true);
      onOpen();
    }
  }, [opened]);

  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        gap: var(--gutter);
        row-gap: 0;
        position: relative;
        padding-top: 1.5rem;
        border-top: 1px solid var(--black);
        cursor: pointer;

        &:hover {
          .history-item--year {
            border-bottom-color: var(--black);
            transition: border-bottom-color 0s;
          }
        }
      `}

      onClick={() => {
        setOpen(!open);
        onOpen();
      }}
      onKeyPress={(e) => {
        e.persist();

        if (e.key === 'Enter') {
          setOpen(!open);
          onOpen();
        }
      }}
    >
      <div
        className="type--mono"
        css={css`
          grid-column: span 2;
          position: relative;
          line-height: 1.8;

          @media (max-width: 900px) {
            grid-column: span 12;
          }
        `}
      >
        <div
          className="history-item--year"
          css={css`
            display: inline-block;
            line-height: 1.2;
            border-bottom: 1px solid transparent;
            transition: border-bottom-color 0.6s var(--curve);
          `}
        >
          {history.year}
        </div>
      </div>
      <PortableText
        css={css`
          grid-column: span 7;
          padding-bottom: ${(history._rawContent.length > 1) ? 0 : '1.5rem'};

          @media (max-width: 900px) {
            grid-column: span 12;
            padding-top: 1rem;
          }

          > * {
            display: none;
          }

          > p:first-of-type {
            display: block;
          }
        `}
        blocks={history._rawContent}
      />
      <div
        css={css`
          position: relative;
          top: -2px;
          grid-column: span 1;
          line-height: 0;
          text-align: right;
          padding-right: 5px;

          @media (max-width: 900px) {
            position: absolute;
            top: 12px;
            right: 0;
          }
        `}
        role="button"
        aria-expanded={(open) ? true : false}
        tabIndex="0"
      >
        <span
          css={css`
            position: absolute;
            top: 9px;
            right: 5px;
            width: 11px;
            height: 1px;
            background-color: var(--black);
          `}
        ></span>
        <span
          css={css`
            position: absolute;
            top: 4px;
            right: 10px;
            width: 1px;
            height: 11px;
            background-color: var(--black);
            transform: rotate(${(!open) ? 0 : '90deg'});
            transition: transform 0.6s var(--curve);
          `}
        ></span>
      </div>
      {(history._rawContent.length > 1 || history.gallery.length > 0) && <SlideDown
        css={css`
          grid-column-start: 3;
          grid-column-end: span 7;
          transition: height 0.6s var(--curve);

          @media (max-width: 900px) {
            grid-column-start: 1;
            grid-column-end: span 12;
          }
        `}
        closed={!open}
      >
        <div>
          <PortableText
            css={css`

              > p:first-of-type {
                display: none;
              }

              > p:last-of-type {
                padding-bottom: 1.5rem;
              }
            `}
            blocks={history._rawContent}
          />
          <div
            css={css`
              display: none;

              @media (max-width: 900px) {
                display: block;
                padding-top: 2.5rem;
                padding-bottom: 1.5rem;
              }
            `}
          >
            {history.gallery.length > 0 && <FadeGallery
              images={history.gallery}
            />}
          </div>
        </div>
      </SlideDown>}
    </div>
  )
}
